
    let imgInfo = {
        collection1: {
            title: 'Upcycled Street Fashion',
            date: 'August, 2021',
            location: 'San Francisco',
            info: 'Shot on a Saturday afternoon in the Mission district.  Models wear corsets made from upcycled Nike clothing from the brand Almost On Time.'
         }
        
     }
 
     export default imgInfo;