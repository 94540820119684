

export default function Header() {

    return (
      <header>
          <a className='logo' href='#'>Kayla Famurewa</a>
          <div className='nav'>
          <a href='#'>work</a>
          <a href='#'>about</a>
          <a href='#'>services</a>
          <a href='#'>contact</a>
          </div>
        </header>
    )
  
  }