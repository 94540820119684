import image1 from './assets/1photo.jpg';
import image2 from './assets/2photo.jpg';
import image3 from './assets/3photo.jpg';
import image4 from './assets/4photo.jpg';
import image5 from './assets/5photo.jpg';
import image6 from './assets/6photo.jpg';
import imageInfo from './imageInfo';

let images = [imageInfo, image1, image2, image3, image4, image5, image6];

export default images;